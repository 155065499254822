<template>
  <div>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">Regions Index</h4>
        <router-link :to="{ name: 'RegionNew'}" tag="button" class="btn btn-success" type="button">Create Region</router-link>
      </div>


    <div class="card">
      <div class="rainbow-background"></div>
      <b-checkbox id="users-archived" v-model="filters.is_archived" v-on:input="getRegions(currentPage, perPage)" switch>
        View Archived Regions
      </b-checkbox>
      <!-- Table Headers and Search Bar -->
      <ransack-filtering :headerWidth="33" :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters" />

      <div class="table-responsive">
        <table class="card-table table">
          <tbody>
          <tr v-for="region in regions" :key="region.id" :id="region.id">
            <td class="text-left">
              {{ region.name }}
            </td>
            <td class="w-50">
              <div class="btn-group float-right">
                <router-link :to="{ name: 'RegionEdit', params: { id: region.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                <router-link :to="{ name: 'RegionShow', params: { id: region.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange" />

    </div>
  </div>
</template>

<script>

import { RegionService } from '@/common/services/region.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';

export default {
  name: 'RegionIndex',
  components: {
    pagination: Pagination,
    RansackFiltering,
  },
  data() {
    return {
      regions: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Name'],
      searchParam: 'name_cont',
      filters: FilterService.defaultData(),
      isAdmin: false,
      isOwner: false,
      isEmployee: false,
    };
  },
  created() {
    this.getRegions(this.currentPage, this.perPage);
  },
  methods: {
    onChange(page, perPage) {
      this.getRegions(page, perPage);
    },
    getRegions(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      RegionService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.regions = response.data;
      });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getRegions(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/regions";
</style>
