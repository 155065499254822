import ApiService from './api.service';

export const RegionService = {
  query(params) {
    return ApiService.query('regions', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('regions', slug);
  },
  create(params) {
    return ApiService.post('regions', { region: params });
  },
  update(slug, params) {
    return ApiService.update('regions', slug, { region: params });
  },
  destroy(slug) {
    return ApiService.delete(`regions/${slug}`);
  },
};

export default RegionService;
